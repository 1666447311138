var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card',{staticClass:"ma-1 pa-3 rounded-xl mb-3"},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('div',{staticClass:"headline primary--text"},[_vm._v("Feriados e férias")])]),(_vm.entityObject)?_c('v-card-text',[[_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"debounce":100,"name":"Nome","vid":"nome","rules":'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Nome","error-messages":errors},model:{value:(_vm.entityObject.nome),callback:function ($$v) {_vm.$set(_vm.entityObject, "nome", $$v)},expression:"entityObject.nome"}})]}}],null,true)})],1),_c('v-col',[_c('validation-provider',{attrs:{"debounce":100,"name":"Tipo","vid":"tipo","rules":'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"label":"Tipo","items":_vm.feriadoTypeOptions,"error-messages":errors},model:{value:(_vm.entityObject.tipo),callback:function ($$v) {_vm.$set(_vm.entityObject, "tipo", $$v)},expression:"entityObject.tipo"}})]}}],null,true)})],1),(_vm.entityObject.tipo == 'Férias')?_c('v-col',[_c('validation-provider',{attrs:{"debounce":100,"name":"Data Inicial","vid":"data_inicial","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-field-component',{attrs:{"propTitle":"Data Inicial","propDisableValidator":true},model:{value:(_vm.entityObject.data),callback:function ($$v) {_vm.$set(_vm.entityObject, "data", $$v)},expression:"entityObject.data"}})]}}],null,true)})],1):_vm._e(),(_vm.entityObject.tipo == 'Férias')?_c('v-col',[_c('validation-provider',{attrs:{"debounce":100,"name":"Data Final","vid":"feriasDataFinal","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-field-component',{attrs:{"propTitle":"Data Final","propDisableValidator":true},model:{value:(_vm.feriasDataFinal),callback:function ($$v) {_vm.feriasDataFinal=$$v},expression:"feriasDataFinal"}})]}}],null,true)})],1):_c('v-col',[_c('validation-provider',{attrs:{"debounce":100,"name":"Data","vid":"data","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-field-component',{attrs:{"propTitle":"Data","propDisableValidator":true},model:{value:(_vm.entityObject.data),callback:function ($$v) {_vm.$set(_vm.entityObject, "data", $$v)},expression:"entityObject.data"}})]}}],null,true)})],1)],1)]],2):_vm._e(),_c('v-card-actions',[_c('save-button',{ref:"saveButton",on:{"submit":_vm.submit}})],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }